@import url("https://fonts.cdnfonts.com/css/zen-dots");
@import url("https://fonts.cdnfonts.com/css/montserrat");

* {
  box-sizing: border-box;
  color: #222222;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

:root {
  --vh: 100%;
}

html {
  background-color: #f3f5f6;
}

body {
  margin: 0;
}

.remove-scrollbar::-webkit-scrollbar {
  display: none;
}

.react-flow__node-card {
  position: absolute;
}

.close-button {
  display: none !important;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
