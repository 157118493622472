@import "https://fonts.cdnfonts.com/css/zen-dots";
@import "https://fonts.cdnfonts.com/css/montserrat";
@import "https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap";
* {
  box-sizing: border-box;
  color: #222;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

:root {
  --vh: 100%;
}

html {
  background-color: #f3f5f6;
}

body {
  margin: 0;
}

.remove-scrollbar::-webkit-scrollbar {
  display: none;
}

.react-flow__node-card {
  position: absolute;
}

.close-button {
  display: none !important;
}

body {
  -webkit-user-select: none;
  user-select: none;
}

#near-wallet-selector-modal {
  --backdrop-bg: rgba(38, 38, 38, .19);
  --heading-color: #222;
  --text-color: #676767;
  --sidebar-border-color: #ededed;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: transparent;
  --wallet-option-border-color: rgba(167, 167, 167, .19);
  --wallet-option-bg-hover: rgba(100, 148, 238, .24);
  --wallet-option-outline-color: #6494ee;
  --content-bg: #fafafa;
  --change-path-bg: #ededed;
  --home-button-bg: #ededed;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #ededed;
  --close-button-fill-icon-color: #9f9f9f;
  --spinner-color: #676767;
  --bottom-section: #ececec;
  --mobile-text: #3e3e3e;
  --connected-green: #47e586;
  --gradient-dark-icon: radial-gradient(297.59% 179.46% at 91.67% -25%, #aec2ea 0%, #95d1e4 100%);
  --get-wallet-option-bg-color: #f8f9fa;
  --get-wallet-option-bg-color-hover: rgba(100, 148, 238, .24);
  --get-wallet-option-border-color: #c1c1c1;
  --get-wallet-outline-color: #6494ee;
  --get-wallet-option-box-shadow: 0px 2px 2px rgba(17, 22, 24, .04);
  --secondary-button-bg-color: rgba(79, 124, 209, .16);
  --secondary-button-border-color: rgba(79, 124, 209, .16);
  --secondary-button-text-color: #4f7cd1;
  --what-wallet-icon-color: #fff;
  --deprecated-icon-bg-color: #d7e2f7;
  --deprecated-icon-bg-color-selected: #4f7cd1;
}

#near-wallet-selector-modal .dark-theme {
  --backdrop-bg: rgba(38, 38, 38, .19);
  --heading-color: #fff;
  --text-color: #c1c1c1;
  --sidebar-border-color: #313030;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: rgba(38, 38, 38, .8);
  --wallet-option-border-color: rgba(167, 167, 167, .19);
  --wallet-option-bg-hover: rgba(79, 124, 209, .24);
  --wallet-option-outline-color: #4f7cd1;
  --content-bg: #232323;
  --change-path-bg: #161616;
  --home-button-bg: #313030;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #313030;
  --close-button-fill-icon-color: #c1c1c1;
  --spinner-color: #fff;
  --bottom-section: #131313;
  --mobile-text: #c1c1c1;
  --connected-green: #47e586;
  --gradient-dark-icon: radial-gradient(124.37% 124.37% at 91.67% -25%, #112140 0%, #262b35 100%);
  --get-wallet-option-bg-color: rgba(0, 0, 0, .12);
  --get-wallet-option-bg-color-hover: rgba(79, 124, 209, .24);
  --get-wallet-option-border-color: #313030;
  --get-wallet-outline-color: #4f7cd1;
  --get-wallet-option-box-shadow: 0px 2px 2px rgba(17, 22, 24, .04);
  --secondary-button-bg-color: rgba(79, 124, 209, .16);
  --secondary-button-border-color: #4f7cd1;
  --secondary-button-text-color: #fff;
  --what-wallet-icon-color: #568ffc;
  --deprecated-icon-bg-color: #2f394e;
  --deprecated-icon-bg-color-selected: #4f7cd1;
}

@media (prefers-color-scheme: dark) {
  #near-wallet-selector-modal {
    --backdrop-bg: rgba(38, 38, 38, .19);
    --heading-color: #fff;
    --text-color: #c1c1c1;
    --sidebar-border-color: #313030;
    --selected-wallet-bg: #4f7cd1;
    --selected-wallet-bg-hover: rgba(38, 38, 38, .8);
    --wallet-option-border-color: rgba(167, 167, 167, .19);
    --wallet-option-bg-hover: rgba(79, 124, 209, .24);
    --wallet-option-outline-color: #4f7cd1;
    --content-bg: #232323;
    --change-path-bg: #161616;
    --home-button-bg: #313030;
    --confirm-button-bg: #5f8afa;
    --confirm-button-bg-hover: #5aa6ff;
    --error: #db5555;
    --close-button-bg-color: #313030;
    --close-button-fill-icon-color: #c1c1c1;
    --spinner-color: #fff;
    --bottom-section: #131313;
    --mobile-text: #c1c1c1;
    --connected-green: #47e586;
    --gradient-dark-icon: radial-gradient(124.37% 124.37% at 91.67% -25%, #112140 0%, #262b35 100%);
    --get-wallet-option-bg-color: rgba(0, 0, 0, .12);
    --get-wallet-option-bg-color-hover: rgba(79, 124, 209, .24);
    --get-wallet-option-border-color: #313030;
    --get-wallet-outline-color: #4f7cd1;
    --get-wallet-option-box-shadow: 0px 2px 2px rgba(17, 22, 24, .04);
    --secondary-button-bg-color: rgba(79, 124, 209, .16);
    --secondary-button-border-color: #4f7cd1;
    --secondary-button-text-color: #fff;
    --what-wallet-icon-color: #568ffc;
    --deprecated-icon-bg-color: #2f394e;
    --deprecated-icon-bg-color-selected: #4f7cd1;
  }
}

.nws-modal-wrapper {
  opacity: 0;
  visibility: hidden;
  color: var(--wallet-selector-text-color, var(--text-color));
  z-index: 80;
  justify-content: center;
  align-items: center;
  font-family: Manrope, sans-serif;
  font-weight: 500;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nws-modal-wrapper .nws-modal-overlay {
  background: var(--wallet-selector-backdrop-bg, var(--backdrop-bg));
  height: 100%;
  width: 100%;
  position: absolute;
}

.nws-modal-wrapper .nws-modal {
  background: var(--wallet-selector-content-bg, var(--content-bg));
  width: 100%;
  max-width: 812px;
  height: 555px;
  background-color: var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 16px;
  font-size: 16px;
  line-height: 1.6;
  transition: visibility linear, opacity .25s, -webkit-transform .25s, transform .25s;
  display: inline-flex;
  position: absolute;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}

.nws-modal-wrapper .nws-modal * {
  box-sizing: border-box;
}

.nws-modal-wrapper .nws-modal button {
  cursor: pointer;
  border: none;
  padding: .5em 1em;
}

.nws-modal-wrapper .nws-modal .modal-left {
  width: 35%;
  border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
  height: 100%;
  padding: 32px 24px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .modal-left::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper {
  margin-bottom: 20px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
  margin-top: 0;
  margin-bottom: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
}

.single-wallet {
  width: 100%;
  cursor: pointer;
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
  padding: 12px;
  display: flex;
}

.single-wallet:focus-within {
  outline: var(--wallet-option-outline-color) solid 2px;
}

.single-wallet .icon {
  height: 48px;
  width: auto;
}

.single-wallet .icon img {
  width: 100%;
  height: auto;
  max-width: 48px;
}

.single-wallet .content {
  width: 50%;
}

.single-wallet .content .name {
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.single-wallet .content .description {
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.single-wallet .button-get {
  margin-left: auto;
  margin-right: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar {
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  column-gap: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover {
  background-color: var(--wallet-selector-wallet-option-bg-hover, var(--wallet-option-bg-hover));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  outline: none;
  padding: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet .content .title {
  color: #fff;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet .icon, .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet .title {
  opacity: .4;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet {
  position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .warning-triangle {
  background: var(--content-bg);
  border-top-left-radius: 63px;
  border-bottom-left-radius: 10px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 42px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover .warning-triangle {
  background-color: var(--deprecated-icon-bg-color);
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.selected-wallet.sidebar .warning-triangle {
  background-color: var(--deprecated-icon-bg-color-selected);
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.connected-wallet .icon:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--connected-green);
  border: 3px solid var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet.connected-wallet .icon:before {
  border: 3px solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content {
  height: 32px;
  width: auto;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .description {
  display: none;
}

.nws-modal-wrapper .nws-modal .modal-right {
  width: 65%;
  padding: 32px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
  margin-top: 91px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper-err {
  margin-top: 45px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  gap: 10px;
  margin: 20px 0 0;
  padding: 0;
  display: grid;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what {
  width: 100%;
  flex-flow: row;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what + .wallet-what {
  margin-top: 50px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side {
  width: 56px;
  height: 56px;
  background: var(--gradient-dark-icon);
  color: var(--what-wallet-icon-color);
  border-radius: 12px;
  padding: 8px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side svg {
  opacity: .7;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side {
  width: 100%;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side h3 {
  color: var(--wallet-selector-text-color, var(--heading-color));
  margin: 0 auto 8px 0;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side p {
  word-break: break-word;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin: 4px auto;
  font-size: 18px;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-body.get-wallet-body {
  row-gap: 16px;
  margin-top: 64px;
  padding-left: 32px;
  padding-right: 0;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  font: inherit;
  color: #fff;
  border-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border: .1em solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border-radius: 4px;
  margin: 25px auto 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
  background-color: var(--wallet-selector-home-button-bg, var(--home-button-bg));
  color: #6494ee;
  border-width: 0;
  border-radius: 4px;
  margin: 4px 0;
  padding: 8px 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .nws-modal-header {
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
  cursor: pointer;
  height: 32px;
  width: 32px;
  background-color: var(--wallet-selector-close-button-bg-color, var(--close-button-bg-color));
  border: 0;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  fill: var(--wallet-selector-close-button-fill-icon-color, var(--close-button-fill-icon-color));
}

.nws-modal-wrapper .nws-modal .back-button {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 4px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-header-wrapper {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button:active {
  background: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  pointer-events: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .action-buttons {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .lang-selector-wrapper {
  display: none;
  position: absolute;
  bottom: 15px;
  right: 50px;
}

.nws-modal-wrapper .nws-modal .lang-selector {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  height: 32px;
  -webkit-user-select: none;
  user-select: none;
  width: 54px;
  z-index: 1;
  text-indent: 54px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjc1IDE5LjI1TDE2IDEyLjc1TDE5LjI1IDE5LjI1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0IDE3LjI1SDE4IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQuNzUgNi43NUgxMy4yNSIgc3Ryb2tlPSIjQzFDMUMxIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05IDYuNVY0Ljc1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTExLjI1IDYuNzVDMTEuMjUgNi43NSAxMS4yNSA5LjI1IDkuMjUgMTEuMjVDNy4yNSAxMy4yNSA0Ljc1IDEzLjI1IDQuNzUgMTMuMjUiIHN0cm9rZT0iI0MxQzFDMSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIuMjUgMTMuMjVDMTIuMjUgMTMuMjUgOS43NSAxMy4yNSA3Ljc1IDExLjI1QzcuMzQ1NTEgMTAuODQ1NSA2Ljc1IDkuNzUgNi43NSA5Ljc1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") 5px / 20px 20px no-repeat;
  border: 0;
  outline: none;
  padding-right: 54px;
  font-size: 16px;
  position: relative;
}

.nws-modal-wrapper .nws-modal .lang-selector-wrapper:after {
  content: "";
  height: 7px;
  width: 7px;
  border: 2px solid #72727a;
  border-width: 2px 2px 0 0;
  display: inline-block;
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  -webkit-transform: rotate(135deg)translateY(-50%);
  transform: rotate(135deg)translateY(-50%);
}

.nws-modal-wrapper .nws-modal .switch-network-message-wrapper .content .network-id {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper {
  padding: 0 26px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
  justify-content: center;
  margin-top: 53px;
  margin-bottom: 40px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description p {
  margin: 14px 0;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description .specify-path {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  cursor: pointer;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
  justify-content: center;
  margin-top: 91px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .display-path {
  background: var(--home-button-bg);
  color: #606060;
  letter-spacing: 1px;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path {
  background: var(--change-path-bg);
  width: 63px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  padding: 0 5px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .path-value {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper p {
  margin: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .path-description {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link {
  text-align: center;
  color: var(--selected-wallet-bg);
  cursor: pointer;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link a {
  color: var(--selected-wallet-bg);
  font-size: 14px;
  text-decoration: none;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper {
  flex-direction: column;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper button {
  width: 23px;
  background-color: var(--home-button-bg);
  border: none;
  padding: 0;
}

.nws-modal-wrapper .nws-modal .no-accounts-found-wrapper {
  margin-top: 50px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account {
  margin-top: 20px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper {
  justify-content: center;
  margin-top: 90px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper input {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  border: 1px solid var(--confirm-button-bg-hover);
  border-radius: 50px;
  padding: 6px 8px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper p {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper {
  justify-content: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper button {
  color: var(--selected-wallet-bg);
  border: 1px solid var(--selected-wallet-bg);
  width: 78px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 600;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .form {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
  color: var(--text-color);
  flex-direction: column;
  margin-bottom: 16px;
  padding: 10px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account input[type="checkbox"] {
  width: 25px;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  padding: 10px;
  -webkit-transform: scale(1.7);
  transform: scale(1.7);
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control label {
  color: inherit;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .action-buttons {
  justify-content: flex-end;
}

.nws-modal-wrapper .nws-modal .overview-wrapper p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .accounts {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .refresh-link {
  color: #5f8afa;
  cursor: pointer;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data p {
  margin: 0 0 0 10px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper p {
  margin: 16px 0;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box {
  width: 40px;
  height: 40px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box img {
  width: 100%;
  height: auto;
}

.open {
  opacity: 1;
  visibility: visible;
  transition: visibility linear, opacity .25s;
}

.nws-modal-wrapper .spinner {
  --size: 160px;
  --border: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  position: relative;
}

.nws-modal-wrapper .spinner .icon {
  width: calc(var(--size) / 1.2);
  height: calc(var(--size) / 1.2);
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .05);
}

.nws-modal-wrapper .spinner img {
  width: 100%;
  height: auto;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .nws-modal-wrapper .nws-modal-wrapper .nws-modal {
    width: 250px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list input {
    max-width: 140px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
    flex-direction: column;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control select {
    text-align: center;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.nws-modal-wrapper .nws-modal-wrapper.dark-theme .spinner .icon {
  box-shadow: 0 10px 20px rgba(255, 255, 255, .05);
}

@media (prefers-color-scheme: dark) {
  .nws-modal-wrapper .spinner .icon {
    box-shadow: 0 10px 20px rgba(255, 255, 255, .05);
  }
}

@-webkit-keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.connecting-wrapper {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.connecting-wrapper .content .icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.connecting-wrapper .content .icon .green-dot {
  width: 30px;
  height: 30px;
  background-color: var(--connected-green);
  border: 5px solid var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
}

.connecting-wrapper .content .icon img {
  width: 100%;
  height: auto;
}

.connecting-wrapper .content h3 {
  margin: 16px 0;
}

.connecting-wrapper .content .connecting-name {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-message {
  margin-top: 16px;
  font-size: 14px;
}

.connecting-wrapper .content .connecting-details {
  align-items: center;
  margin-top: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-details span {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.connecting-wrapper .content .connecting-details .spinner {
  width: 25px;
  height: auto;
  -webkit-animation: 2s linear infinite spinner;
  animation: 2s linear infinite spinner;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection button {
  background: var(--secondary-button-bg-color);
  border: 1px solid var(--secondary-button-border-color);
  color: var(--secondary-button-text-color);
  border-radius: 4px;
  gap: 8px;
  padding: 10px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper {
  vertical-align: middle;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error {
  color: #ce5a6f;
  vertical-align: middle;
  text-align: center;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper .error svg {
  color: var(--wallet-selector-content-bg, var(--content-bg));
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper p {
  word-break: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin: 14px 0;
  display: -webkit-box;
  overflow: hidden;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .success {
  color: #4fd98f;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  padding: 25px;
}

.connecting-wrapper .content .connecting-details {
  margin-top: 20px;
}

.connecting-wrapper .wallet-connected-success {
  align-items: center;
  display: flex;
}

.connecting-wrapper .wallet-connected-success svg {
  color: var(--content-bg);
}

.connecting-wrapper .wallet-connected-success span {
  color: var(--wallet-selector-connected-green, var(--connected-green));
  margin-left: 10px;
  font-size: 14px;
}

.single-wallet-get {
  width: 100%;
  height: 100%;
  border: 1px solid var(--get-wallet-option-border-color);
  box-shadow: var(--get-wallet-option-box-shadow);
  background-color: var(--get-wallet-option-bg-color);
  cursor: pointer;
  border-radius: 8px;
  flex-flow: column wrap;
  flex-grow: 1;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto auto;
  padding: 14px;
  display: flex;
  position: relative;
}

.single-wallet-get:hover {
  background: var(--get-wallet-option-bg-color-hover);
}

.single-wallet-get:focus-within {
  outline: var(--get-wallet-outline-color) solid 2px;
}

.single-wallet-get .icon {
  min-width: 32px;
  max-height: 32px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 5px;
  position: relative;
}

.single-wallet-get .icon img {
  width: 100%;
  height: auto;
  max-width: 48px;
}

.single-wallet-get .title {
  color: var(--heading-color);
  text-align: center;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.single-wallet-get .type {
  color: var(--text-color);
  text-align: center;
  margin-top: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.single-wallet-get .small-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.single-wallet-get .small-icon svg {
  color: #4c5155;
}

.single-wallet-get:hover .small-icon svg {
  color: #4f7cd1;
}

.single-wallet-get .description {
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.single-wallet-get .button-get {
  margin-left: auto;
  margin-right: 0;
}

.connected-flag {
  color: var(--connected-green);
  border: 1px solid var(--connected-green);
  border-radius: 50px;
  align-items: center;
  padding: 3px 10px;
  display: flex;
}

.connected-flag:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--connected-green);
  border-radius: 50%;
  margin-right: 5px;
  display: block;
}

.scan-qr-code {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.scan-qr-code .qr-code > div:first-of-type {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 20px;
}

.scan-qr-code .qr-code {
  height: calc(100% - 200px);
  border: 1px solid var(--wallet-selector-content-bg, var(--content-bg));
  text-align: center;
  border-radius: 4px;
  margin-top: 64px;
}

.scan-qr-code .qr-code svg {
  width: 239px;
  height: 239px;
}

.scan-qr-code .qr-code .copy-btn {
  cursor: pointer;
  text-align: center;
  color: var(--selected-wallet-bg);
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.scan-qr-code .qr-code .copy-btn svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.scan-qr-code .qr-code .notification {
  font-size: 14px;
}

.scan-qr-code .footer {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px;
  font-size: 14px;
  display: flex;
  position: relative;
  bottom: 0;
}

.scan-qr-code .footer .btn {
  background: var(--secondary-button-bg-color);
  color: var(--secondary-button-text-color);
  border: 1px solid var(--secondary-button-border-color);
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 577px) {
  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile {
    display: none;
  }

  .scan-qr-code .footer {
    position: absolute;
  }
}

@media (min-width: 769px) {
  .button-spacing {
    margin: 90px;
  }
}

@media (max-width: 768px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    margin-top: 45px;
  }

  .button-spacing {
    margin: 45px;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    width: 40%;
    border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
    height: 100%;
    max-height: 70vh;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    width: 60%;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    padding: 0 0 0 10px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
    margin-top: 30px;
    margin-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
    margin-top: 20px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
    text-align: center;
  }

  .nws-modal-wrapper .wallet-not-installed-wrapper > p {
    max-width: 500px;
    margin: 20px 0 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
    background-color: var(--wallet-selector-home-button-bg, var(--content-bg));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-hide {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile p {
    text-align: center;
    margin: auto;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal {
    width: 100%;
    height: 500px;
    background: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
    border-radius: 16px 16px 0 0;
    display: block;
    bottom: 0;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    width: 100%;
    background-color: var(--wallet-selector-content-bg, var(--content-bg));
    height: auto;
    padding: 32px 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body {
    display: flex;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body .wallet-options-wrapper {
    margin: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    width: 100%;
    background-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
  }

  .nws-modal-wrapper .nws-modal .connecting-wrapper .content {
    padding-top: 0;
  }

  .connecting-wrapper .content .icon .green-dot {
    border-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
    text-align: center;
    margin: 4px auto;
    font-size: 16px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle.-open {
    padding-right: 32px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .content {
    text-align: center;
    color: var(--mobile-text);
    margin: 0;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body {
    margin-top: 10px;
    padding: 0;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
    margin: 25px auto 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-header {
    text-align: center;
    font-size: 18px;
    display: block;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header h2 {
    text-align: center;
    font-size: 18px;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper {
    margin-bottom: 0;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
    grid-gap: 0;
    gap: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .info {
    width: 90px;
    display: none;
  }

  .single-wallet {
    min-width: 76px;
    max-width: 76px;
    display: block;
  }

  .single-wallet.sidebar .icon {
    width: 56px;
    height: 56px;
    margin: auto;
  }

  .single-wallet.sidebar .content {
    width: auto;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
    text-align: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    display: -webkit-box;
    overflow: hidden;
    margin-left: 0 !important;
  }

  .single-wallet.wallet-connect, .single-wallet.my-near-wallet {
    word-break: break-word;
  }

  .single-wallet-get:hover {
    background-color: rgba(79, 124, 209, .16);
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image, .nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description > p {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  .nws-modal-wrapper ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: var(--backdrop-bg);
  }

  .nws-modal-wrapper ::-webkit-scrollbar-thumb:horizontal {
    background: var(--close-button-fill-icon-color);
    border-radius: 10px;
  }
}

/*# sourceMappingURL=index.a4205714.css.map */
